import { createContext, useContext, useEffect, useMemo, useState } from "react";

const UserContext = createContext(null);

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [additionalData, setAdditionalData] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [refreshUser, setRefreshUser] = useState(true);
  const value = useMemo(
    () => ({ user, loadingUser, additionalData, setRefreshUser }),
    [user, loadingUser, additionalData, setRefreshUser]
  );

  useEffect(() => {
    if (refreshUser) {
      setLoadingUser(true);
      setRefreshUser(false);
      fetch(`/api/users/check_authenticated`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          setAdditionalData(data.additionalData);
          setUser(data.user);
          localStorage.setItem("user", JSON.stringify(data.user));
          setLoadingUser(false);
        })
        .catch((err) => {
          setUser(null);
          setLoadingUser(false);
        });
    } else if (localStorage.getItem("user") !== "undefined") {
      setUser(JSON.parse(localStorage.getItem("user")));
      setLoadingUser(false);
    }
  }, [refreshUser]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
