import { Box, Typography, useMediaQuery } from "@mui/material";
import MainNavBar from "./NavBar/MainNavBar";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";
import { useEffect } from "react";

function Public(props) {
  const { loadingUser, user } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (loadingUser) return;
    if (!user?.first_name || sessionStorage.getItem("redirected")) return;
    sessionStorage.setItem("redirected", "true");
    navigate("/courses");
  }, [loadingUser, user?.first_name]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const header = document.getElementById("main").children[0].children[0];
    window.addEventListener("scroll", function () {
      if (window.scrollY > 32) header.style.position = "fixed";
      else header.style.position = "absolute";
    });
  }, []);

  return (
    <>
      <Box
        id="main"
        position="relative"
      >
        <MainNavBar />
        {props.component}
      </Box>
      <Footer color="black" />
    </>
  );
}

export default Public;
