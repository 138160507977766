import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Link } from "react-router-dom";
import { trackInitialize } from "../_helpers/trackingAnalytics";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Logo from "../assets/icon-dark.webp";
import Slide from "@mui/material/Slide";
import { useTheme } from "@emotion/react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CookieBanner = () => {
  const [open, setOpen] = useState(
    localStorage.getItem("cookieBanner") === null
  );

  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));

  const handleAccept = () => {
    setOpen(false);
    localStorage.setItem("cookieBanner", "true");
    trackInitialize();
  };

  const handleDecline = () => {
    setOpen(false);
    localStorage.setItem("cookieBanner", "false");
    trackInitialize();
  };

  return (
    <Dialog
      open={open}
      onClose={handleAccept}
      sx={{
        "& .MuiDialog-container": {
          alignItems: {
            xs: "flex-end",
            sm: "center",
          },
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: "rgba(255,255,255,1)",
          boxShadow: "none",
          color: "black",
          borderRadius: {
            xs: 0,
            sm: 4,
          },
          p: {
            xs: 1,
            sm: 2,
          },
          m: 0,
          maxWidth: {
            xs: "100%",
            sm: "444px",
          },
        },
      }}
      keepMounted={!smAndUp}
      TransitionComponent={!smAndUp ? Transition : undefined}
    >
      <DialogContent>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <img
            src={Logo}
            alt="logo"
            style={{
              width: "20%",
              height: "auto",
              margin: "auto",
            }}
          />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "2rem",
              color: "dark.main",
              mb: 2,
            }}
          >
            Cookies
          </Typography>
        </Box>

        <DialogContentText
          align="center"
          sx={{
            color: "gray.main",
          }}
        >
          We use cookies to improve your experience on our website. By accepting
          this cookie policy, you consent to our use of cookies. Learn more
          about our cookie policy in our
          <Button
            component={Link}
            to="/cookiepolicy"
            sx={{
              color: "yellow.main",
              fontWeight: 500,
              textDecoration: "underline",
            }}
          >
            Cookie Policy{" "}
          </Button>
          page.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleAccept}
          color="yellow"
          sx={{
            flex: 5,
            borderRadius: 4,
            boxShadow: 0,
            fontWeight: 600,
            color: "white.main",
            textTransform: "none",
          }}
        >
          I Agree
        </Button>
        <Button
          variant="text"
          onClick={handleDecline}
          sx={{
            flex: 2,
            boxShadow: 0,
            fontWeight: 600,
            color: "gray.main",
            textTransform: "none",
            textDecoration: "underline",
          }}
        >
          Decline
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookieBanner;
