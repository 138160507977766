import { Link } from "react-router-dom";
import logoInline from "../assets/icon-light-text-inline.webp";
import { pages } from "./MainNavBar";
import { socialMediaLinks } from "./SocialMedia";

export const footers = [
  {
    title: (
      <Link to="/">
        <img
          src={logoInline}
          alt="Logo"
          style={{
            width: "100%",
            maxWidth: "300px",
          }}
        />
      </Link>
    ),
    big: true,
    content: [
      {
        title: "Qriptal",
        description:
          "Building a complete trading environment for crypto traders",
      },
    ],
  },
  {
    title: "Navigation",
    content: pages.slice(0, -1),
  },
  {
    title: "Resources",
    content: [
      { title: "FAQ", path: "/#faq" },
      { title: "Contact Us", path: "mailto:administration@qriptal.com" },
      { title: "Discord", path: "https://discord.gg/N6Pnc2VNz5" },
      { title: "Account", path: "/account" },
      { title: "Platform", path: "/dashboard" },
    ],
  },
  {
    title: "Follow Us",
    content: socialMediaLinks.slice(0, -1).map((link) => ({
      title: link.platform,
      icon: link.icon,
      path: link.link,
    })),
  },
];
