import { useState, useCallback } from "react";
import { Menu, MenuItem, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

export default function UserMenu({ user, userSettingsPages, handleLogoutOption }) {
  const [anchorUser, setAnchorUser] = useState(false);
  const handleOpenUserMenu = (e) => setAnchorUser(e.currentTarget);

  return (
    <>
      <AccountCircleOutlinedIcon
        onClick={handleOpenUserMenu}
        color="white"
        fontSize="large"
        sx={{
          cursor: "pointer",
          ml: 3,
          transition: "color 0.25s",
          ":hover": {
            color: "yellow.main",
          },
        }}
      />

      <Menu
        anchorEl={anchorUser}
        open={Boolean(anchorUser)}
        onClose={() => setAnchorUser(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            mt: 2,
            backgroundColor: "black.main",
            border: "1px solid",
            borderColor: "white.main",
            borderRadius: 2,
            color: "white.main",
          },
        }}
      >
        <Typography variant="body1" fontWeight={700} pr={4} pl={1} mb={1}>
          Hello {user?.first_name}!
        </Typography>

        {userSettingsPages
          .filter((item) => item.dropdown)
          .map((item) => (
            <MenuItem
              key={item.title}
              component={NavLink}
              to={item.path}
              onClick={() => item.logout && handleLogoutOption(item.title)}
              dense
            >
              <Typography
                variant="subtitle1"
                sx={{
                  ":hover": {
                    color: "yellow.main",
                  },
                }}
              >
                {item.title}
              </Typography>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
