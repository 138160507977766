import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Box,
  useMediaQuery,
  Drawer,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  DashboardOutlined as DashboardOutlinedIcon,
  AnalyticsOutlined as AnalyticsOutlinedIcon,
  SchoolOutlined as SchoolOutlinedIcon,
  CandlestickChartOutlined as CandlestickChartOutlinedIcon,
  QuestionAnswerOutlined as QuestionAnswerOutlinedIcon,
  LocalLibraryOutlined as LocalLibraryOutlinedIcon
} from "@mui/icons-material";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import { useEffect, useState } from "react";
import AssessmentInfo from "./components/AssessmentInfo";

function TrainingSideMenu({ sideMenuRef, open, setOpen }) {
  const [page, setPage] = useState("");

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const location = window.location.pathname;

  useEffect(() => {
    if (location === "/dashboard") setPage("Dashboard");
    else if (location === "/courses") setPage("Courses");
    else if (location === "/library") setPage("Library");
    else if (location === "/trade") setPage("Trade");
    else if (location === "/analytics") setPage("Analytics");
    else if (location === "/faq") setPage("FAQ");
  }, [location]);

  const sections = [
    {
      title: "Dashboard",
      icon: <DashboardOutlinedIcon color="white" />,
      class: "dashboard",
      url: "/dashboard",
    },
    {
      title: "Courses",
      icon: <SchoolOutlinedIcon color="white" />,
      class: "Courses",
      url: "/courses",
    },
    {
      title: "Library",
      icon: <LocalLibraryOutlinedIcon color="white" />,
      class: "Library",
      url: "/library",
    },
    {
      title: "Trade",
      icon: <CandlestickChartOutlinedIcon color="white" />,
      class: "Trade",
      url: "/trade",
    },
    {
      title: "Analytics",
      icon: <AnalyticsOutlinedIcon color="white" />,
      class: "analytics",
      url: "/analytics",
    },
    {
      title: "FAQ",
      icon: <QuestionAnswerOutlinedIcon color="white" />,
      class: "FAQ",
      url: "/faq",
    },
  ];

  const MenuContainer = ({ children }) =>
    isMobile ? (
      <Drawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            width: "60%",
            maxWidth: "300px",
            backgroundColor: "dark.main",
            "&::-webkit-scrollbar": {
              width: "0.25em",
            },
            "&::-webkit-scrollbar-track": {
              my: 2,
              px: 1,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "dark.light",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-track-piece": {
              backgroundColor: "dark.main",
              border: "none",
            },
          },
        }}
      >
        <Box
          sx={{
            pt: "48px", // height of navbar
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: "100%",
            px: open ? 1 : 0,
          }}
        >
          {children}
        </Box>
      </Drawer>
    ) : (
      <Box
        id="sidebar"
        ref={sideMenuRef}
        sx={{
          minWidth: open ? "20%" : "0px",
          px: open ? 1 : 0,
          transition: "width 0.25s",
          pt: 2,
          backgroundColor: "dark.main",
          position: "fixed",
          top: "0",
          display: "flex",
          flexDirection: "column",
          mt: "48px", // height of navbar
          height: "calc(100vh - 48px)",
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <NavigateBeforeOutlinedIcon
            color="white"
            sx={{
              position: "absolute",
              display: open ? "block" : "none",
              top: "50%",
              left: "100%",
              transform: "translate(-50%, -50%)",
              fontSize: "2.5rem",
              cursor: "pointer",
              p: 0.5,
              margin: "auto",
              backgroundColor: "dark.main",
              borderRadius: "50%",
              zIndex: 1,
              ":hover": { color: "yellow.main" },
            }}
            onClick={() => setOpen(!open)}
          />
          {children}
        </Box>
      </Box>
    );

  return (
    <MenuContainer>
      <List
        sx={{
          display: open ? "block" : "none",
          flex: 1,
        }}
      >
        {sections.map((section, index) => (
          <ListItem key={index} alignItems="center" disablePadding>
            <ListItemButton
              sx={{
                mb: 1,
                px: { xs: 1, lg: 2 },
                borderRadius: 2,
                backgroundColor:
                  page === section.title ? "yellow.main" : "transparent",
                ":hover": {
                  backgroundColor:
                    page === section.title ? "yellow.main" : "tradeGray.main",
                },
              }}
              component={Link}
              to={section.url}
              onClick={() => (isMobile ? setOpen(false) : null)}
            >
              <ListItemIcon
                sx={{
                  justifyContent: "center",
                  minWidth: open ? "2px" : "auto", // min-width: 2 is a hack to make the width take the default value
                }}
              >
                {section.icon}
              </ListItemIcon>
              <ListItemText
                primary={section.title}
                primaryTypographyProps={{
                  sx: { fontWeight: 600, color: "white.main", ml: 2 },
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List
        sx={{
          display: open ? "block" : "none",
          borderTop: "2px solid",
          borderRadius: 0,
          borderColor: "light.dark",
          mt: 2,
        }}
      >
        <ListItem alignItems="center" disablePadding>
          <ListItemButton
            sx={{
              px: { xs: 1, lg: 2 },
              borderRadius: 2,
              ":hover": {
                backgroundColor: "tradeGray.main",
              },
            }}
            component={Link}
            to={"/"}
          >
            <ListItemIcon
              sx={{
                minWidth: open ? "2px" : "auto", // min-width: 2 is a hack to make the width take the default value
                flex: 1,
                justifyContent: "right",
              }}
            >
              <ArrowCircleLeftRoundedIcon color="white" />
            </ListItemIcon>
            <ListItemText
              primary={"Exit"}
              primaryTypographyProps={{
                sx: { fontWeight: 600, color: "white.main", ml: 2 },
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </MenuContainer>
  );
}

export default TrainingSideMenu;
