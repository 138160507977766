import { useState, useCallback } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  Stack,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import logo from "../../assets/icon-light-text-inline.webp";
import logoNoText from "../../assets/icon-light-no-text.webp";
import { pages, userSettingsPages } from "../../content/MainNavBar";
import { useUser } from "../../context/UserContext";
import UserMenu from "./components/UserMenu";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const NavButton = ({ item, ...props }) => (
  <Button
    id={`nav-${item.title.replace(" ", "-").toLowerCase()}`}
    sx={{
      textTransform: "none",
      transition: "all 0.25s",
      fontWeight: item.contained ? 700 : 400,
    }}
    component={NavLink}
    to={item.path}
    variant={item.contained ? "contained" : "text"}
    color={item.contained ? item.color : "white"}
    {...props}
  >
    {item.title}
  </Button>
);

function MainNavBar() {
  const { user, setRefreshUser } = useUser();
  const [mobileOpen, setMobileOpen] = useState(false);

  const [logoWidth, setLogoWidth] = useState(0);
  const [userPagesWidth, setUserPagesWidth] = useState(0);
  const measuredLogoWidthRef = useCallback((node) => {
    if (node !== null) setLogoWidth(node.offsetWidth);
  }, []);
  const measuredUserPagesWidthRef = useCallback((node) => {
    if (node !== null) setUserPagesWidth(node.offsetWidth);
  }, []);

  const handleLogoutOption = useCallback((item) => {
    if (item === "Logout") {
      fetch(`/api/users/logout`, {
        method: "POST",
        credentials: "include",
      })
        .then((res) => res.json())
        .then(() => setRefreshUser(true));
    }
  }, []);

  const drawer = (
    <Stack
      sx={{
        backgroundColor: "black.main",
        color: "white.main",
        width: "max-content",
        px: 2,
        mb: 2,
      }}
      spacing={1.5}
    >
      {pages.map((item) => (
        <NavButton
          key={item.title}
          item={item}
          onClick={() => setMobileOpen(false)}
        />
      ))}

      {userSettingsPages
        .filter((item) => user && item.auth && !item.dropdown)
        .map((item) => (
          <NavButton
            key={item.title}
            item={item}
            onClick={() => setMobileOpen(false)}
          />
        ))}
    </Stack>
  );

  return (
    <>
      <AppBar
        color="black"
        sx={{
          px: { xs: 0, sm: 2, md: 3, lg: 4 },
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar sx={{ position: "relative !important" }}>
          {/* LOGO */}
          <Button
            component={NavLink}
            to="/"
            onClick={() => window.scrollTo(0, 0)}
            sx={{ p: 0 }}
            ref={measuredLogoWidthRef}
          >
            <Box
              component="img"
              sx={{
                content: {
                  xs: `url(${logoNoText})`,
                  sm: `url(${logo})`,
                },
                maxHeight: "32px",
                width: "auto",
              }}
              alt="Logo"
            />
          </Button>

          {/* Handle all pages  */}
          <Box
            sx={{
              flexGrow: 1,
              px: 2,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              gap: 1,
              transition: "transform 0.25s",
              transform: `translateX(calc(-${logoWidth / 2}px + ${
                userPagesWidth / 2
              }px))`,
            }}
          >
            {pages.map((item) => (
              <NavButton key={item.title} item={item} />
            ))}
          </Box>

          {/* Handle User */}
          <Box
            ref={measuredUserPagesWidthRef}
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "right",
              height: "100%",
            }}
          >
            {userSettingsPages
              .filter(
                (item) => (user ? item.auth : !item.auth) && !item.dropdown
              )
              .map((item) => (
                <NavButton key={item.title} item={item} />
              ))}

            {user && (
              <UserMenu
                user={user}
                userSettingsPages={userSettingsPages}
                handleLogoutOption={handleLogoutOption}
              />
            )}
          </Box>

          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              flexGrow: 1,
              justifyContent: "right",
              height: "100%",
            }}
          >
            {user ? (
              <UserMenu
                user={user}
                userSettingsPages={userSettingsPages}
                handleLogoutOption={handleLogoutOption}
              />
            ) : (
              userSettingsPages
                .filter((item) => !item.dropdown && !item.auth)
                .map((item) => <NavButton key={item.title} item={item} />)
            )}

            <IconButton
              aria-label="toggle drawer"
              edge="start"
              onClick={() => setMobileOpen(!mobileOpen)}
              color="white"
              sx={{
                ml: 3,
              }}
            >
              {mobileOpen ? (
                <CloseIcon fontSize="large" />
              ) : (
                <MenuIcon fontSize="large" />
              )}
            </IconButton>
          </Box>
        </Toolbar>

        <Box component="nav" display={{ xs: "none", md: "block" }}>
          <Drawer
            hideBackdrop
            variant="temporary"
            anchor="top"
            open={mobileOpen}
            onClose={() => setMobileOpen(false)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            PaperProps={{
              sx: {
                backgroundColor: "black.main",
                alignItems: "center",
              },
            }}
          >
            <Toolbar />

            {drawer}
          </Drawer>
        </Box>
      </AppBar>
      <Toolbar />
    </>
  );
}
export default MainNavBar;
