import { createContext, useContext, useMemo, useState } from "react";

const CoursesContext = createContext(null);

export function useCourses() {
  return useContext(CoursesContext);
}

export function CoursesProvider({ children }) {
  const [courses, setCourses] = useState([]);

  const value = useMemo(
    () => ({ courses, setCourses }),
    [courses]
  );


  return <CoursesContext.Provider value={value}>{children}</CoursesContext.Provider>;
}
