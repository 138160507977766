import { Box, Skeleton, useMediaQuery } from "@mui/material";
import TrainingNavBar from "./NavBar/TrainingNavBar";
import TrainingSideMenu from "./NavBar/TrainingSideMenu";
import { useUser } from "../context/UserContext";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Platform(props) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { user, loadingUser } = useUser();
  const [ready, setReady] = useState(false);
  const [open, setOpen] = useState(isMobile ? false : true);
  const navigate = useNavigate();
  const { pathname, search, hash } = useLocation();

  const [width, setWidth] = useState(0);


  const sideMenuRef = useCallback((node) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      if (node.clientWidth > 0) setWidth(node.clientWidth);
    });
    resizeObserver.observe(node);
  }, []);

  useEffect(() => {
    if (loadingUser) return;
    const originalPath = pathname + search + hash;
    if (!user?.first_name) navigate("/login" + "?redirect=" + originalPath); 
    else {
      if (!sessionStorage.getItem("redirected"))
        sessionStorage.setItem("redirected", "true");
      setReady(true);
    }
  }, [loadingUser, user]);

  return (
    <Box
      sx={{
        backgroundColor: "tradeGray.main",
        minHeight: "100vh",
      }}
    >
      {ready ? (
        <>
          <TrainingNavBar open={open} setOpen={setOpen} />
          <Box
            sx={{
              display: "flex",
            }}
          >
            <TrainingSideMenu
              sideMenuRef={sideMenuRef}
              open={open}
              setOpen={setOpen}
            />

            <Box
              component="main"
              sx={{
                position: "relative",
                width: "100%",
                pl: isMobile
                  ? "0px"
                  : open
                  ? `${width}px`
                  : props.fullWidth
                  ? "0px"
                  : `${width / 2}px`,
                pr:
                  !isMobile && !open && !props.fullWidth
                    ? `${width / 2}px`
                    : "0px",
                "& > *": {
                  mx: open || !props.emptySpace ? "0px" : "auto",
                  width: isMobile || !props.emptySpace ? "100%" : "80%",
                },
              }}
            >
              {props.component}
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ width: "100%", p: 8 }}>
          <Skeleton variant="text" height={210} />
          <Skeleton variant="rectangular" height={210} />
          <Skeleton variant="text" height={210} />
        </Box>
      )}
    </Box>
  );
}

export default Platform;
