import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  SvgIcon,
  Divider,
  Stack,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { footers } from "../content/Footer";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

function Copyright(props) {
  return (
    <Typography variant="body1" align="center" color="inherit" {...props}>
      {"Copyright © "}
      <Typography
        component={NavLink}
        to="/"
        color="yellow.main"
        sx={{ textDecoration: "none", fontWeight: 500 }}
      >
        Qriptal
      </Typography>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function Footer({ color }) {
  return (
    <Box
      component="footer"
      id="footer"
      sx={{
        pt: 8,
        px: {
          xs: 2,
          sm: 3,
          md: 4,
          lg: 5,
        },
        width: "100%",
        backgroundColor: `${color}.main`,
        color: "white.main",
        zIndex: 1000,
      }}
    >
      <Grid container>
        {footers.map((section) => (
          <Grid
            xs={12}
            sm={6}
            md={section.big ? 6 : 2}
            key={section.title}
            sx={{ p: 4 }}
          >
            <Typography variant="body1" mb={1} fontWeight={700}>
              {section.title}
            </Typography>
            <List>
              {section.content.map((item) => (
                <ListItem key={item.title} disablePadding>
                  {item.title === "Qriptal" ? (
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "body1",
                      }}
                      primary={item.description}
                    />
                  ) : (
                    <ListItemButton
                      component={NavLink}
                      to={item.path}
                      dense
                      sx={{
                        px: 0,
                        transition: "color 0.3s",
                        "&:hover": {
                          color: "yellow.main",
                        },
                      }}
                    >
                      {item.icon && (
                        <ListItemIcon
                          sx={{
                            color: "inherit",
                            fontSize: "1rem",
                            minWidth: "auto",
                            mr: { xs: 1, sm: 2 },
                          }}
                        >
                          <SvgIcon
                            titleAccess={item.title}
                            sx={{ flexShrink: 2 }}
                          >
                            {item.icon}
                          </SvgIcon>
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primaryTypographyProps={{
                          variant: "body1",
                        }}
                        primary={item.title}
                      />
                    </ListItemButton>
                  )}
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>

      <Divider sx={{ my: 4, borderColor: "white.dark" }} />

      <Stack
        useFlexGap
        flexWrap="wrap"
        direction="row"
        justifyContent="space-between"
        color="white.dark"
        pb={4}
        px={8}
        sx={{
          pb: 4,
          px: {
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
          },
        }}
      >
        <Copyright />
        <Stack useFlexGap flexWrap="wrap" direction="row" spacing={2}>
          <Typography
            component={Link}
            to={"/termsandconditions"}
            variant="subtitle1"
            color="inherit"
            textDecoration="underline"
          >
            Terms & Conditions
          </Typography>
          <Typography
            component={Link}
            to={"/privacypolicy"}
            variant="subtitle1"
            color="inherit"
            textDecoration="underline"
          >
            Privacy Policy
          </Typography>
          <Typography
            component={Link}
            to={"/cookiepolicy"}
            variant="subtitle1"
            color="inherit"
            textDecoration="underline"
          >
            Cookie Policy
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Footer;
