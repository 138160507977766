import { ReactComponent as Facebook } from "../assets/SVGs/facebook.svg";
import { ReactComponent as Twitter } from "../assets/SVGs/twitter.svg";
import { ReactComponent as Instagram } from "../assets/SVGs/instagram.svg";
import { ReactComponent as YouTube } from "../assets/SVGs/youtube.svg";
import { ReactComponent as LinkedIn } from "../assets/SVGs/linkedin.svg";
import { ReactComponent as Discord } from "../assets/SVGs/discord.svg";

export const socialMediaLinks = [
  {
    platform: "Facebook",
    link: "https://www.facebook.com/Qriptal.fb",
    icon: <Facebook />,
  },
  {
    platform: "Twitter",
    link: "https://twitter.com/qriptal",
    icon: <Twitter />,
  },
  {
    platform: "Instagram",
    link: "https://www.instagram.com/qriptal/",
    icon: <Instagram />,
  },
  {
    platform: "LinkedIn",
    link: "https://www.linkedin.com/company/qriptal/",
    icon: <LinkedIn />,
  },
  {
    platform: "Youtube",
    link: "https://www.youtube.com/@qriptal",
    icon: <YouTube />,
  },
  {
    platform: "Discord",
    link: "https://discord.gg/N6Pnc2VNz5",
    icon: <Discord />,
  },
];
