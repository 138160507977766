import { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import logo from "../../assets/icon-light-training-text-inline.webp";
import logoNoText from "../../assets/icon-light-no-text.webp";
import StringAvatar from "./components/StringsAvatar";
import { useUser } from "../../context/UserContext";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { GiUpgrade } from "react-icons/gi";

function TrainingNavbar({ open, setOpen }) {
  const { user, setRefreshUser } = useUser();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [anchorUser, setAnchorUser] = useState(false);

  const handleOpenUserMenu = (e) => {
    setAnchorUser(e.currentTarget);
  };

  const handleLogoutOption = (item) => {
    if (item === "Logout") {
      fetch(`/api/users/logout`, {
        method: "POST",
        credentials: "include",
      })
        .then((res) => res.json())
        .then(() => {
          setRefreshUser(true);
          window.location.href = "/";
        });
    }
    setAnchorUser(null);
  };

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        id: "navbar",
        display: "flex",
        marginBottom: "48px",
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "dark.main",
          py: 1,
          height: "48px",
          transition: "height 0.15s ease-out",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          variant="dense"
          sx={{
            height: "100%",
            minHeight: "0px",
          }}
        >
          {open ? (
            <Button
              component={Link}
              to="/dashboard"
              onClick={() => {
                scroll.scrollToTop({
                  duration: 100,
                });
              }}
              sx={{
                p: 0,
                height: "100%",
                ml: {
                  sm: 0,
                },
              }}
            >
              <Box
                component="img"
                sx={{
                  content: {
                    xs: open ? `url(${logo})` : `url(${logoNoText})`,
                    sm: `url(${logo})`,
                  },
                  height: {
                    xs: open ? "60%" : "100%",
                    sm: "100%",
                  },
                }}
                alt="Logo"
              />
            </Button>
          ) : (
            <IconButton
              aria-label="toggle drawer"
              edge="start"
              onClick={() => setOpen(true)}
              sx={{ mr: 2, color: "light.main" }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Box
            sx={{
              justifyContent: "right",
              ml: "auto",
              display: "flex",
              alignSelf: "stretch",
            }}
          >

            {!user?.paid &&
              (isMobile ? (
                <Button
                  sx={{
                    width: "80px",
                    height: "40px",
                    minWidth: "80px",
                    borderRadius: 8,
                    backgroundColor: "yellow.main",
                    transform: "translateX(50%)",
                    justifyContent: "flex-start",
                  }}
                  onClick={() => {
                    navigate("/account?ref=navbar#pricing");
                  }}
                >
                  <GiUpgrade
                    style={{ color: "white", marginLeft: "4px" }}
                    size={20}
                  />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate("/account?ref=navbar#pricing");
                  }}
                  sx={{
                    borderRadius: 8,
                    textTransform: "none",
                    color: "white.main",
                    mr: 2,
                    fontWeight: 700,
                    px: 4,
                  }}
                >
                  Upgrade Now
                </Button>
              ))}

            <Tooltip title="Account settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={user?.first_name}
                  {...StringAvatar(`${user?.first_name} ${user?.last_name}`)}
                />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorUser}
              open={Boolean(anchorUser)}
              onClose={handleLogoutOption}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiPaper-root": {
                  mt: 2,
                  backgroundColor: "dark.main",
                  border: "1px solid",
                  borderColor: "white.main",
                  borderRadius: 2,
                },
              }}
            >
              <Typography
                align="left"
                sx={{ color: "white.main", fontWeight: 600, mx: 1 }}
              >
                Hello {user?.first_name}!
              </Typography>
              <Typography
                align="left"
                sx={{ color: "white.main", fontWeight: 200, mb: 2, mx: 1 }}
              >
                {user?.paid ? "Member" : "Not a member"}
              </Typography>

              <MenuItem
                key="account"
                component={Link}
                to="/account"
                onClick={() => handleLogoutOption("Account")}
                sx={{
                  ":hover": {
                    backgroundColor: "tradeGray.main",
                    "& .MuiSvgIcon-root": {
                      color: "yellow.main",
                    },
                    ".MuiTypography-root": {
                      color: "yellow.main",
                    },
                  },
                }}
              >
                <PersonOutlineOutlinedIcon sx={{ mr: 1 }} color="white" />
                <Typography
                  textAlign="center"
                  sx={{
                    ":hover": {
                      color: "yellow.main",
                    },
                    color: "white.main",
                    fontWeight: 600,
                    pr: 4,
                  }}
                >
                  Account
                </Typography>
              </MenuItem>
              <MenuItem
                key="logout"
                onClick={() => handleLogoutOption("Logout")}
                sx={{
                  ":hover": {
                    backgroundColor: "tradeGray.main",
                    "& .MuiSvgIcon-root": {
                      color: "yellow.main",
                    },
                    ".MuiTypography-root": {
                      color: "yellow.main",
                    },
                  },
                }}
              >
                <LogoutOutlinedIcon sx={{ mr: 1 }} color="white" />
                <Typography
                  textAlign="center"
                  sx={{
                    color: "white.main",
                    fontWeight: 600,
                  }}
                >
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
export default TrainingNavbar;
