export const pages = [
  { title: "Home", path: "/#home" },
  { title: "Features", path: "/#features" },
  { title: "Track Record", path: "/#track-record" },
  { title: "Modules", path: "/#modules" },
  { title: "Pricing", path: "/#pricing" },
  { title: "FAQ", path: "/#faq" },

];
export const userSettingsPages = [
  { title: "Login", path: "/login", auth: false, logout: false, contained: true, color: "white", dropdown: false },
  { title: "Platform", path: "/courses", auth: true, logout: false, contained: true, color: "yellow", dropdown: false },
  { title: "Settings", path: "/account", auth: true, logout: false, dropdown: true },
  { title: "Logout", path: "/", auth: true, logout: true, dropdown: true },
];
