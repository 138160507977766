import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useUser } from "./UserContext";

const SseContext = createContext(null);

export function useSse() {
  return useContext(SseContext);
}

export function SseProvider({ children }) {
  const { user, setRefreshUser } = useUser();
  const [trades, setTrades] = useState([]);
  const [wallet, setWallet] = useState({});
  const [trainings, setTrainings] = useState([]);
  const [payments, setPayments] = useState([]);
  const [random, setRandom] = useState(null);

  const value = useMemo(
    () => ({ trades, wallet, trainings, payments }),
    [trades, wallet, trainings, payments]
  );

  useEffect(() => {
    if (user && Object.keys(user).length) {
      const random = Math.random().toString(36).substring(2, 15);
      setRandom(random);
      const es = new EventSource(`/api/users/sse?identification=${random}`, {
        withCredentials: true,
      });

      es.onmessage = (e) => {
        const data = JSON.parse(e.data);
        const trades = data.trades;
        const user = data.user;
        const wallet = data.wallet;
        const trainings = data.trainings;
        const payments = data.payments;
        if (trades && trades.length > 0) {
          for (let trade of trades) {
            for (let key of Object.keys(trade)) {
              if (typeof trade[key] !== "object") continue;
              trade[key] = Number.parseFloat(trade[key]["$numberDecimal"]);
            }
          }
          setTrades(trades.reverse());
        }
        if (wallet && Object.keys(wallet).length > 0) {
          const updatedWallet = {};
          for (let key of Object.keys(wallet)) {
            if (typeof wallet[key] !== "object") {
              updatedWallet[key] = wallet[key];
              continue;
            }
            updatedWallet[key] = Number.parseFloat(
              wallet[key]["$numberDecimal"]
            );
          }
          setWallet(updatedWallet);
        }
        if (user) setRefreshUser(true);
        if (trainings && trainings.length > 0) setTrainings(trainings);
        if (payments && payments.length > 0) setPayments(payments);
      };

      return () => {
        // if (user && Object.keys(user).length) {
        //   fetch(`/api/users/closeSse`, {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //     credentials: "include",
        //     body: JSON.stringify({
        //       identification: random,
        //     }),
        //   });
        //   es.close();
        //   setRandom(null);
        // }
      };
    }
  }, [user?.id]);

  useEffect(() => {
    window.addEventListener("beforeunload", removeSse);
    window.addEventListener("pagehide", removeSse);
    return () => {
      window.removeEventListener("beforeunload", removeSse);
      window.removeEventListener("pagehide", removeSse);
    };
  });

  const removeSse = () => {
    if (user && Object.keys(user).length) {
      fetch(`/api/users/closeSse`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          identification: random,
        }),
      });
      setRandom(null);
    }
  };

  return <SseContext.Provider value={value}>{children}</SseContext.Provider>;
}
