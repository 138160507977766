import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const trackInitialize = () => {
  if (localStorage.getItem("cookieBanner") === "true") {
    window.gtag("js", new Date());
    window.gtag("config", "G-V1LDDX7GY9", {
      send_page_view: false,
      app_name: "Qriptal",
      cookie_flags: "max-age=7200;secure;samesite=none",
    });
    window.gtag("consent", "default", {
      ad_storage: "granted",
      analytics_storage: "granted",
    });
    // window.fbq("consent", "grant");
    // window.fbq("init", "1399025190939660");
    // window.fbq("track", "PageView");
  } else {
    window.gtag("consent", "default", {
      ad_storage: "denied",
      analytics_storage: "denied",
    });
    // window.fbq("consent", "revoke");
  }
};

const usePageTracking = () => {
  const location = useLocation();

  // Google Analytics to support page view tracking
  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);
};

const useScreenviewTracking = () => {
  useEffect(() => {
    window.gtag("event", "screen_view", { screen_name: "Website" });
  }, []);
};

const trackLogin = (method, email) => {
  window.gtag("event", "login", { method: method, email: email });
  // window.fbq("trackCustom", "Login", { method: method, email: email });
};

const trackSignup = (method, email) => {
  window.gtag("event", "sign_up", { method: method, email: email });
  // window.fbq("trackCustom", "SignUp", { method: method, email: email });
};

const trackBeginCheckout = () => {
  window.gtag("event", "begin_checkout");
  // window.fbq("track", "InitiateCheckout");
};

const trackCTA = (source) => {
  window.gtag("event", "flow_from_cta", { source: source });
};

const trackBeginVideo = (
  user,
  video_current_time,
  video_duration,
  video_title,
  video_url
) => {
  window.gtag("event", "video_start", {
    email: user.email,
    video_current_time: video_current_time,
    video_duration: video_duration,
    video_title: video_title,
    video_url: video_url,
  });
  // window.fbq("track", "BeginVideo", { video_id: videoId, email: user.email, id: user.id});
};

const trackProgressVideo = (
  user,
  video_current_time,
  video_duration,
  video_title,
  video_url
) => {
  window.gtag("event", "video_progress", {
    email: user.email,
    video_current_time: video_current_time,
    video_duration: video_duration,
    video_title: video_title,
    video_url: video_url,
  });
  // window.fbq("track", "BeginVideo", { video_id: videoId, email: user.email, id: user.id});
};

const trackCompleteVideo = (
  user,
  video_current_time,
  video_duration,
  video_title,
  video_url
) => {
  window.gtag("event", "video_complete", {
    email: user.email,
    video_current_time: video_current_time,
    video_duration: video_duration,
    video_title: video_title,
    video_url: video_url,
  });
  // window.fbq("track", "CompleteVideo", { video_id: videoId, email: user.email, id: user.id});
};

const trackStartAssessmentQuestionnaire = (user) => {
  window.gtag("event", "start_assessment", {
    email: user.email
  });
};

const trackDoneAssessmentQuestionnaire = (user) => {
  window.gtag("event", "done_assessment", {
    email: user.email
  });
};

const trackStartAssessmentTrading = (user) => {
  window.gtag("event", "start_assessment_trading", {
    email: user.email
  });
}

const trackStartYoutubeVideo = (user, videoId, videoTitle) => {
  window.gtag("event", "start_youtube_video", {
    email: user.email,
    video_id: videoId,
    video_title: videoTitle
  });
};

export {
  trackInitialize,
  usePageTracking,
  useScreenviewTracking,
  trackLogin,
  trackSignup,
  trackBeginCheckout,
  trackBeginVideo,
  trackProgressVideo,
  trackCompleteVideo,
  trackCTA,
  trackStartAssessmentQuestionnaire,
  trackDoneAssessmentQuestionnaire,
  trackStartAssessmentTrading,
  trackStartYoutubeVideo
};
