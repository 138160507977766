import { Alert, Snackbar } from "@mui/material";
import { createContext, useContext, useMemo, useState } from "react";

const SnackbarContext = createContext(null);

export function useSnackbar() {
  return useContext(SnackbarContext);
}

export function SnackbarProvider({ children }) {
  const [snack, setSnack] = useState({
    message: "",
    severity: "",
    open: false,
    autoHideDuration: 6000,
    onClose: null,
  });

  const value = useMemo(
    () => ({
      snack,
      setSnack,
    }),
    [snack]
  );


  return (
    <SnackbarContext.Provider value={value}>
      <Snackbar
        open={snack.open}
        autoHideDuration={snack.autoHideDuration}
        onClose={snack.onClose}
      >
        <Alert
          onClose={snack.onClose}
          severity={snack.severity}
        >
          {snack.message}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
}
